import { errorHandler } from '@/api/utils'
import { http } from '@/plugins/ky'
import { Estimation } from '@/api/types'

export type Estimates = {
  initial: Estimation
  subscription: Estimation
}

export const get = (amount: number) =>
  new Promise<Estimates>((resolve, reject) =>
    http
      .get('customer/service/domain/estimate', {
        searchParams: {
          amount,
        },
      })
      .then(async (r: any) => {
        const res = await r.json()
        resolve(res)
      })
      .catch(async ({ response, message }: any) => {
        await errorHandler(response, message)
        reject(undefined)
      })
  )
