
import { defineComponent, ref, reactive, toRefs, onBeforeMount } from 'vue'
import { useTranslation } from '@/locales'
import { useRoute, useRouter } from 'vue-router'
import { get as getServiceDomainCurrent } from '@/api/customer/service/domain/current'
import { get as getServiceDomainEstimate } from '@/api/customer/service/domain/estimate'
import { Estimation } from '@/api/types'
import { post as postOrder } from '@/api/customer/order'

export default defineComponent({
  setup() {
    const items = reactive<{
      nextPayment: Estimation | undefined
      initial: Estimation | undefined
    }>({
      nextPayment: undefined,
      initial: undefined,
    })
    const { nextPayment } = toRefs(items)
    const { initial } = toRefs(items)

    const currentZoneNum = ref(0)
    const router = useRouter()
    const route = useRoute()
    const plan = ref('')
    const { locale } = route.params
    const zoneNum = ref('1')
    const nextMonths = ref('')
    const initialMonths = ref('')
    const freeZoneNum = ref(0)
    const enable = ref(false)
    const { t, n, d } = useTranslation()
    const openDialog = ref(false)

    const getNextTerms = (estimate: Estimation) => {
      if (estimate.paymentFrequency === 'monthly') {
        return d(new Date(estimate.startDate), 'm')
      } else {
        const start = d(new Date(estimate.startDate), 'terms')
        const end = d(new Date(estimate.endDate), 'terms')
        return `${start} - ${end}`
      }
    }

    const getInitialTerms = (estimate: Estimation) => {
      if (estimate.paymentFrequency === 'monthly') {
        return d(new Date(estimate.endDate), 'm')
      } else {
        const start = d(new Date(estimate.startDate), 'terms')
        const end = d(new Date(estimate.endDate), 'terms')
        return `${start} - ${end}`
      }
    }

    const updateEstimation = () =>
      getServiceDomainEstimate(Number(zoneNum.value))
        .then((res) => {
          nextPayment.value = res.subscription
          if (nextPayment.value != null) {
            nextMonths.value = getNextTerms(res.subscription)
          }

          initial.value = res.initial
          if (initial.value != null) {
            initialMonths.value = getInitialTerms(res.initial)
          }
        })
        .catch((res) => {
          initial.value = undefined
          nextPayment.value = undefined
        })

    const validate = () => {
      zoneNum.value = zoneNum.value.replace(/\D/g, '')
      if (zoneNum.value.length >= 3) {
        zoneNum.value = zoneNum.value.slice(0, 2)
      }

      if (zoneNum.value === '0') {
        zoneNum.value = '1'
      }

      if (zoneNum.value.length > 0) {
        updateEstimation()
      }

      if (Number(zoneNum.value) === freeZoneNum.value) {
        nextPayment.value = undefined
      }

      enable.value = zoneNum.value.length > 0
    }

    const blurEvent = () => {
      if (zoneNum.value.length === 0) {
        zoneNum.value = '1'
        updateEstimation()
      }

      if (Number(zoneNum.value) <= freeZoneNum.value) {
        zoneNum.value = String(freeZoneNum.value)
        updateEstimation()
      }
    }

    const setCurrent = (res: Estimation) => {
      freeZoneNum.value = res.freeZoneNum
      currentZoneNum.value = res.amount + res.freeZoneNum!
      zoneNum.value = String(res.reserveAmount + res.freeZoneNum!)
      plan.value =
        res.paymentFrequency === 'monthly' ? t('Monthly') : t('Yearly')

      if (res.reserveAmount > 0) {
        nextMonths.value = getNextTerms(res)
        nextPayment.value = res
      }
    }

    onBeforeMount(() =>
      getServiceDomainCurrent()
        .then((res) => {
          setCurrent(res)
        })
        .catch(() => (nextPayment.value = undefined))
    )

    const onClick = () => {
      enable.value = false
      postOrder('qt-dns-zones', '', Number(zoneNum.value), false).then(
        async (res) => {
          if (res.sessionId != null) {
            console.log(`sessionId ${res.sessionId}`)
            router.push({
              path: `/${locale}/service/order/confirm`,
              query: {
                id: res.sessionId,
              },
            })
          } else if (res.noSession != null) {
            await getServiceDomainCurrent()
              .then((res) => {
                setCurrent(res)
                openDialog.value = true
              })
              .catch(() => {
                nextPayment.value = undefined
              })
          }
        }
      )
    }

    const closeDialog = () => (openDialog.value = false)

    return {
      t,
      n,
      onClick,
      validate,
      blurEvent,
      closeDialog,
      openDialog,
      enable,
      plan,
      currentZoneNum,
      zoneNum,
      initial,
      nextPayment,
      nextMonths,
      initialMonths,
      freeZoneNum,
    }
  },
})
