import { errorHandler } from '@/api/utils'
import { http } from '@/plugins/ky'
import { Estimation } from '@/api/types'

export const get = () =>
  new Promise<Estimation>((resolve, reject) =>
    http
      .get('customer/service/domain/current')
      .then(async (r: any) => {
        const res = await r.json()
        console.log(3333, res)
        resolve(res)
      })
      .catch(async ({ response, message }: any) => {
        console.log(4444, response, message)
        await errorHandler(response, message)
        reject(undefined)
      })
  )
