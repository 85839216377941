import * as Sentry from '@sentry/browser'

import { errorHandler } from '@/api/utils'
import { http } from '@/plugins/ky'
import { stripeRedirect } from '@/api/utils'

export type Session = {
  sessionId?: string
  noSession?: boolean
}

export const post = (
  serviceName: string,
  paymentFrequency: string,
  amount: number,
  agreement: boolean
) =>
  new Promise<Session>((resolve, reject) =>
    http
      .post('customer/order', {
        json: {
          paymentFrequency,
          serviceName,
          amount,
          agreement,
        },
      })
      .then(async (r: any) => {
        const session = await r.json()
        resolve(session)
      })
      .catch(async ({ response, message }: any) => {
        console.log('errorrr')
        await errorHandler(response, message)
        reject()
      })
  )
