<template>
  <div class="container mx-auto md:w-5/6">
    <h1 class="text-2xl text-gray-600 text-left font-bold my-3 md:my-10">
      {{ t('QUALITIA DNS ZONES OPTION') }}
    </h1>
    <div v-if="currentZoneNum > 0" class="grid md:grid-cols-2 gap4">
      <div class="text-left">
        <h1 class="mb-20">
          {{ t('The current zone amount') }} {{ currentZoneNum }}
        </h1>
        <div class="mb-20">
          <div class="flex">
            <h1 class="">{{ t('The zone after changed') }}</h1>
            <input
              @input="validate"
              v-model="zoneNum"
              v-on:blur="blurEvent()"
              class="ml-4 text-right px-2 rounded-md border border-gray-500 w-10"
            />
          </div>
          <p v-if="Number(zoneNum) < freeZoneNum" class="text-red-600 text-sm">
            {{ t('Invalid parameter: more than {num}', { num: freeZoneNum }) }}
          </p>
        </div>
        <h1>{{ t('Payment method') }}：{{ plan }}</h1>
      </div>
      <template v-if="nextPayment != null">
        <div v-if="nextPayment != null" class="text-left">
          <template v-if="initial != null">
            <h1 class="my-3">
              {{ t('Payment for now') }} ({{
                t('For {terms}', { terms: initialMonths })
              }})
            </h1>
            <table class="border-b-2">
              <tr class="text-left border-b-2">
                <th class="py-2 text-xs md:text-base">{{ t('Rate price') }}</th>
                <th class="py-2 text-xs md:text-base">{{ t('Amount') }}</th>
                <th class="py-2 text-xs md:text-base">
                  {{ t('Total amount') }}
                </th>
              </tr>
              <tr>
                <td class="w-96 py-2 text-left text-xs md:text-base">
                  {{ n(initial.ratePrice, 'currency') }}
                </td>
                <td class="w-60 py-2 text-left text-xs md:text-base">
                  {{ initial.amount }}
                </td>
                <td class="w-60 py-2 text-left text-xs md:text-base">
                  {{ n(initial.total, 'currency') }}
                </td>
              </tr>
            </table>
            <div class="flex justify-center md:justify-end items-center mt-10">
              <div class="md:mr-4">
                <div class="flex mb-6">
                  <div class="">
                    <h2 class="w-22 text-left mb-2">
                      {{ t('Total amount with tax') }}：
                    </h2>
                  </div>
                  <div class="">
                    <p class="text-right mb-2">
                      {{ n(initial.totalWithTax, 'currency') }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <h1 class="mb-3">
            {{ t('Payment for the next subscription') }} ({{
              t('For {terms}', { terms: nextMonths })
            }})
          </h1>
          <table class="border-b-2">
            <tr class="text-left border-b-2">
              <th class="py-2 text-xs md:text-base">{{ t('Rate price') }}</th>
              <th class="py-2 text-xs md:text-base">{{ t('Amount') }}</th>
              <th class="py-2 text-xs md:text-base">{{ t('Total amount') }}</th>
            </tr>
            <tr v-if="nextPayment.freeZoneNum > 0" class="border-b-2">
              <td class="w-96 py-2 text-left text-xs md:text-base">
                {{ n(0, 'currency') }}
              </td>
              <td class="w-60 py-2 text-left text-xs md:text-base">
                {{ nextPayment.freeZoneNum }}
              </td>
              <td class="w-60 py-2 text-left text-xs md:text-base">
                {{ n(0, 'currency') }}
              </td>
            </tr>
            <tr v-if="nextPayment.reserveAmount > 0">
              <td class="w-96 py-2 text-left text-xs md:text-base">
                {{ n(nextPayment.ratePrice, 'currency') }}
              </td>
              <td class="w-60 py-2 text-left text-xs md:text-base">
                {{ nextPayment.reserveAmount }}
              </td>
              <td class="w-60 py-2 text-left text-xs md:text-base">
                {{ n(nextPayment.total, 'currency') }}
              </td>
            </tr>
          </table>
          <p class="text-gray-400 text-sm mb-10">
            {{
              t('Note: The {zoneNum} zones is free', { zoneNum: freeZoneNum })
            }}
          </p>
          <div class="flex justify-center md:justify-end items-center mt-10">
            <div class="md:mr-4">
              <div class="flex mb-6">
                <div class="">
                  <h2 class="w-22 text-left mb-2">
                    {{ t('Total amount with tax') }}：
                  </h2>
                </div>
                <div class="">
                  <p class="text-right mb-2">
                    {{ n(nextPayment.totalWithTax, 'currency') }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="text-right">
            <button
              :disabled="zoneNum.length === 0 || !enable"
              @click="onClick"
              class="bg-blue-500 text-white text-lg px-10 rounded-md"
              :class="{
                disable: zoneNum.length === 0 || !enable,
              }"
            >
              {{ t('Update') }}
            </button>
          </div>
        </div>
      </template>
    </div>
    <div
      v-if="openDialog"
      class="fixed z-10 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>

        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block w-96 align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
        >
          <div class="bg-white text-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  id="modal-title"
                  class="text-lg leading-6 font-medium text-gray-600 font-bold"
                >
                  {{ t('Updated optional zone number') }}
                </h3>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              class="mt-3 w-32 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              @click="closeDialog"
            >
              {{ t('Close') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, reactive, toRefs, onBeforeMount } from 'vue'
import { useTranslation } from '@/locales'
import { useRoute, useRouter } from 'vue-router'
import { get as getServiceDomainCurrent } from '@/api/customer/service/domain/current'
import { get as getServiceDomainEstimate } from '@/api/customer/service/domain/estimate'
import { Estimation } from '@/api/types'
import { post as postOrder } from '@/api/customer/order'

export default defineComponent({
  setup() {
    const items = reactive<{
      nextPayment: Estimation | undefined
      initial: Estimation | undefined
    }>({
      nextPayment: undefined,
      initial: undefined,
    })
    const { nextPayment } = toRefs(items)
    const { initial } = toRefs(items)

    const currentZoneNum = ref(0)
    const router = useRouter()
    const route = useRoute()
    const plan = ref('')
    const { locale } = route.params
    const zoneNum = ref('1')
    const nextMonths = ref('')
    const initialMonths = ref('')
    const freeZoneNum = ref(0)
    const enable = ref(false)
    const { t, n, d } = useTranslation()
    const openDialog = ref(false)

    const getNextTerms = (estimate: Estimation) => {
      if (estimate.paymentFrequency === 'monthly') {
        return d(new Date(estimate.startDate), 'm')
      } else {
        const start = d(new Date(estimate.startDate), 'terms')
        const end = d(new Date(estimate.endDate), 'terms')
        return `${start} - ${end}`
      }
    }

    const getInitialTerms = (estimate: Estimation) => {
      if (estimate.paymentFrequency === 'monthly') {
        return d(new Date(estimate.endDate), 'm')
      } else {
        const start = d(new Date(estimate.startDate), 'terms')
        const end = d(new Date(estimate.endDate), 'terms')
        return `${start} - ${end}`
      }
    }

    const updateEstimation = () =>
      getServiceDomainEstimate(Number(zoneNum.value))
        .then((res) => {
          nextPayment.value = res.subscription
          if (nextPayment.value != null) {
            nextMonths.value = getNextTerms(res.subscription)
          }

          initial.value = res.initial
          if (initial.value != null) {
            initialMonths.value = getInitialTerms(res.initial)
          }
        })
        .catch((res) => {
          initial.value = undefined
          nextPayment.value = undefined
        })

    const validate = () => {
      zoneNum.value = zoneNum.value.replace(/\D/g, '')
      if (zoneNum.value.length >= 3) {
        zoneNum.value = zoneNum.value.slice(0, 2)
      }

      if (zoneNum.value === '0') {
        zoneNum.value = '1'
      }

      if (zoneNum.value.length > 0) {
        updateEstimation()
      }

      if (Number(zoneNum.value) === freeZoneNum.value) {
        nextPayment.value = undefined
      }

      enable.value = zoneNum.value.length > 0
    }

    const blurEvent = () => {
      if (zoneNum.value.length === 0) {
        zoneNum.value = '1'
        updateEstimation()
      }

      if (Number(zoneNum.value) <= freeZoneNum.value) {
        zoneNum.value = String(freeZoneNum.value)
        updateEstimation()
      }
    }

    const setCurrent = (res: Estimation) => {
      freeZoneNum.value = res.freeZoneNum
      currentZoneNum.value = res.amount + res.freeZoneNum!
      zoneNum.value = String(res.reserveAmount + res.freeZoneNum!)
      plan.value =
        res.paymentFrequency === 'monthly' ? t('Monthly') : t('Yearly')

      if (res.reserveAmount > 0) {
        nextMonths.value = getNextTerms(res)
        nextPayment.value = res
      }
    }

    onBeforeMount(() =>
      getServiceDomainCurrent()
        .then((res) => {
          setCurrent(res)
        })
        .catch(() => (nextPayment.value = undefined))
    )

    const onClick = () => {
      enable.value = false
      postOrder('qt-dns-zones', '', Number(zoneNum.value), false).then(
        async (res) => {
          if (res.sessionId != null) {
            console.log(`sessionId ${res.sessionId}`)
            router.push({
              path: `/${locale}/service/order/confirm`,
              query: {
                id: res.sessionId,
              },
            })
          } else if (res.noSession != null) {
            await getServiceDomainCurrent()
              .then((res) => {
                setCurrent(res)
                openDialog.value = true
              })
              .catch(() => {
                nextPayment.value = undefined
              })
          }
        }
      )
    }

    const closeDialog = () => (openDialog.value = false)

    return {
      t,
      n,
      onClick,
      validate,
      blurEvent,
      closeDialog,
      openDialog,
      enable,
      plan,
      currentZoneNum,
      zoneNum,
      initial,
      nextPayment,
      nextMonths,
      initialMonths,
      freeZoneNum,
    }
  },
})
</script>
<style scoped lang="postcss">
.disable {
  @apply bg-gray-400 text-white;
}
</style>
